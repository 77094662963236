<template>
  <div id="page-login" class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6" xl="4">
          <CCardGroup>
            <CCard class="border-0">
              <CCardBody class="login-box position-relative">

                <div class="login-logo">
                  <!-- <img src="@/assets/rose.png"> -->
                  <h2 class="text-center py-4">LOGIN</h2>
                </div>

                <CForm method="post" @submit.prevent="login">

                  <CInput
                    placeholder="USERNAME"
                    autocomplete="username"
                    v-model="input.username"
                    required
                  >
                  </CInput>

                  <CInput
                    placeholder="PASSWORD"
                    type="password"
                    autocomplete="curent-password"
                    v-model="input.password"
                    required
                  >
                  </CInput>

                  <div class="text-center">
                    <button type="submit" class="btn-login">LOGIN MEMBER</button>
                  </div>
                </CForm>

                <loading
                  :active="isLoading"
                  :can-cancel="false"
                  :is-full-page="false"
                  background-color="#EEEEEE"
                  :height="80"
                  :width="80"
                  color="#51CE8D"
                  loader="dots"
                />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AuthService from '@/services/authService'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'Login',
  data() {
    return {
      isSubmit: false,
      input: {
        username: '',
        password: ''
      },
      isLoading: false
    }
  },
  methods: {
    login(e) {

      this.isLoading = true
      AuthService.login(this.input)
      .then(async (response)=>{
        console.log('response', response)
        if(response.success) {
          await Auth.saveLogin(response.data)
          this.$router.push({name: 'Home'})
        }else{
          throw new Error(response?.data?.message)
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ',
          text: e.message || 'Username/Password ไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    const viewport = document.querySelector("meta[name=viewport]")
    viewport.setAttribute('content', 'width=device-width,initial-scale=1.0')
  }
}
</script>
<style lang="scss">
#page-login {
  // background-color: #FCDE7B;
  background-image: url('../../assets/green-bg.webp');
  background-size: cover;

  .login-logo {
    img {
      width: 100%;
      max-width: 100%;
      padding: 20px 0 40px 0;
    }
  }

  .card {
    background-color: transparent;
    border: 0;

    .vld-background {
      border-radius: 15px;
      background: none;
    }

    .login-box {
      background-color: #FFFFFF;
      border-radius: 15px;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: 0 0 50px #111111;
      border: 2px solid #EEEEEE;

      .login-logo {
        h2 {
          color: #031E1E;
        }
        img {
          width: 100%;
          max-width: 100%;
        }
      }

      form {
        padding: 0 10px;
        // margin-top: -10px;

        .form-group {
          border-bottom: 1px solid #EEEEEE;

          .form-control {
            border-radius: 0;
            background-color: transparent;
            border: 0;
            color: #111111;
            padding: 0;
          }

          .form-control::placeholder {
            color: #111111;
            opacity: 0.5;
          }

          .form-control:focus {
            outline: none !important;
            border: 0 !important;
            box-shadow: 0 0 0;
          }
        }

        .btn-login {
          border: 0;
          width: 100%;
          font-weight: normal;
          background: linear-gradient(135deg, rgba(0,168,80,1) 0%, rgba(97,214,153,1) 100%);
          margin: 20px auto 30px;
          padding: 10px 0;
          border-radius: 20px;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
